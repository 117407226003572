<template>
  <div class="view content-padding">

    <SvgLoader v-if="!dataLoaded" :loading="true"  :bgColor="'transparent'" :width="'100%'"/>

    <TableOverview v-if="dataLoaded"
      kind="module"
      :page-title="labels.module_overview"
      :searchable="true"
      :settings="settings"
      :footer-buttons="footerButtons"
      :get-page="getModulesMt"
      :items="items"
    />

    <router-view name="create_module" @saved="moduleCreated" />

    <router-view
      name="create_module_select_mb"
      vuex-module="monetizationBundle"
      action-name="getMonetizationBundleList"
      :route-back="{
        name: 'create-module',
        query: { page: $route.query.page },
      }"
    />
  </div>
</template>

<script>
import TableOverview from "@/views/Dashboard/Common/TableOverview/";
import SvgLoader from "@/components/Preloaders/SvgLoader";
const colors = require("/colors.config.json");
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "Overview",
  components: {
    TableOverview,
    SvgLoader
  },
  data(){
    return {
      dataLoaded: false,
      items: {}
    }
  },
  computed: {
    ...mapState("helper", {
      admin_roles: "admin_roles",
    }),
    ...mapGetters("helper", ["labels"]),
    footerButtons() {
      if (this.hasRole("super") || this.hasRole("creator"))
        return [
          {
            label: this.labels.create_new,
            variant: "btn-purple",
            action: () => {
              if (this.$route.name !== "create-module") {
                this.$router.push({
                  name: "create-module",
                  query: {
                    page: this.$route.query.page,
                  },
                });
              }
            },
          },
        ];

      return [];
    },
    settings() {
      return {
        header: [
          {
            headerLabel: "Name",
            headerColor: `${colors.gray_text}`,
            key: "title",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 1 30%",
            whiteSpace: "nowrap",
          },
          // {
          // 	headerLabel: 'Status',
          // 	headerColor: `${colors.gray_text}`,
          // 	key: 'status',
          // 	computeFn: input => input,
          // 	bodyColor: `${colors.gray_text_2}`,
          // 	columnWidth: '1 1 55%',
          //     textAlign: 'center',
          // },
        ],
        sortable: false,
        // sortStyles: { flex: '0 0 5%', color: `${colors.gray_text}` },
        minTableWidth: "300px",
        actionsWidth: "0 0 10%",
        actionsHeaderLabel: "",
        actions: [
          {
            label: this.labels.open,
            variant: "btn-purple",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (data) =>
              this.$router.push({
                name: "module-editor",
                params: { id: data._id },
              }),
          },
        ],
      };
    },
  },
  methods: {
    ...mapMutations("helper", {
      setTablePagination: "setTablePagination",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("_module", {
      getModules: "getList",
    }),
    moduleCreated() {
      this.getModulesMt(this.$route.query.page);
    },
    getModulesMt(page) {
      let data = { page };
      if (!this.$route.query.page) data.page = 1;
      else if (!page) data.page = this.$route.query.page;

      this.getModules(data)
        .then((res) => {
          if (res.success) {
            this.items = res.data.items
            this.setTablePagination(res.data.pages);
            this.dataLoaded = true
            if (
              Number(this.$route.query.page) !== Number(res.data.pages.current)
            ) {
              this.$router.push({ query: { page: res.data.pages.current } });
            }
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    hasRole(role) {
      if (Array.isArray(this.admin_roles) && this.admin_roles.includes(role))
        return true;

      return false;
    },
  },
  mounted() {
    this.getModulesMt();
  },
};
</script>

<style lang="scss" scoped></style>
